export default [

  {
    path: '/tingees',
    name: 'tingees',
    component: () => import('@/views/settings/tingee/list/TingeeList.vue'),
    meta: {
      resource: 'setting',
      action: 'read',
    },
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/settings/general/GeneralSetting.vue'),
    meta: {
      resource: 'setting',
      action: 'read',
    },
  },
  {
    path: '/templates',
    name: 'templates',
    component: () => import('@/views/settings/template/list/TemplateList.vue'),
    meta: {
      resource: 'setting',
      action: 'read',
    },
  },
];
